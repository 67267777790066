import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import GreenDot from "../../../public/assets/images/home/GreenDot.webp";
import bottomRightBackground from "../../../public/assets/images/home/bottomRightBackground.webp";
import { PRODUCT_ACCOUNT_LIST, PRODUCT_LIST } from "../../../data/StaticList";
import Image from "next/image";
import { useRouter } from "next/router";

const OurProducts: React.FC = () => {
  const router = useRouter();
  const [isClicked, setIsClicked] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.removeItem("hash");
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("hash")) {
      setTimeout(() => {
        const foundIndex = PRODUCT_LIST.findIndex(
          (item) => item.id === localStorage.getItem("hash")
        );
        if (foundIndex !== -1) {
          setIsClicked(foundIndex);
        }
      }, 50);
    }
    setTimeout(() => {
      localStorage.removeItem("hash");
    }, 1000);
  }, [router]);

  return (
    <Grid
      id="our-products"
      sx={{
        minHeight: { xs: "70vh", md: "80vh" },
        width: "100%",
        backgroundColor: "#F2F7FF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        padding: { xs: "50px 0", md: "80px 0" },
      }}
    >
      {/* WRAPPER - DIV */}
      <Grid
        style={{
          height: "100%",
          width: "85%",
          zIndex: 1,
          maxWidth: "1440px",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "40px" },
            fontWeight: "700",
            textAlign: { xs: "center", md: "start" },
          }}

        >
          Sell more. Earn more.
        </Typography>

        <Grid
          sx={{
            margin: { xs: "20px 0px 10px 0px", md: "40px 0 20px 0" },
          }}
          style={{
            display: "flex",
            alignItems: "left",
            overflow: "scroll",
            whiteSpace: "nowrap",
          }}
        >
          {PRODUCT_LIST.map((item, i) => {
            return (
              <Grid
                key={i}
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  margin: {
                    xs: "0px 16px!important",
                    md:
                      i === 0 ? "0px 24px 0 0!important" : "0px 24px!important",
                  },
                }}
                className={
                  i === isClicked ? "AccountType" : "AccountType_notClicked"
                }
                onClick={() => setIsClicked(i)}
              >
                <Grid
                  container
                  sx={{
                    height: { xs: "14px", md: "18px" },
                    width: { xs: "14px", md: "17px" },
                    marginRight: "4px",
                  }}
                >
                  <Image
                    src={item.icon}
                    alt={item.alt}
                    width={500}
                    height={500}
                    style={{
                      fontWeight: "bold",
                    }}
                    loading="lazy"
                  />
                </Grid>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  sx={{ fontSize: { xs: "16px", md: "18px" },color:'black' }}
                >
                  {item.title}
                </Typography>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          sx={{
            display: { xs: "block", md: "flex" },
            minHeight: { xs: "40vh", md: "50vh" },
          }}
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* LEFT SIDE BOX */}
          <Grid
            sx={{
              height: { xs: "300px", md: "350px" },
              width: { xs: "300px", md: "350px" },
              marginRight: { xs: "0", md: "40px" },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={PRODUCT_ACCOUNT_LIST[isClicked].leftIcon}
              alt="phoneIMg"
              width={500}
              height={500}
              style={{
                width: "100%",
                height: "100%",
              }}
              loading="lazy"
            />
          </Grid>

          {/* RIGHT SIDE BOX */}
          <Grid
            sx={{
              width: { xs: "100%", md: "35%" },
              padding: { xs: "0", md: "15px" },
              marginLeft: { xs: "0", md: "40px" },
            }}
            style={{
              minHeight: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid sx={{ minHeight: { xs: "", md: "150px" } }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "28px" },
                  margin: { xs: "10px 0", md: "25px 0" },
                  fontWeight: "700",
                }}
              
              >
                {PRODUCT_ACCOUNT_LIST[isClicked].heading}
              </Typography>
              {PRODUCT_ACCOUNT_LIST[isClicked].points.map((item) => {
                return (
                  <Typography
                    variant="body2"
                    key={item}
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: { xs: "16px 0", md: "10px 0" },
                      fontWeight: 500,
                    }}
                  >
                    <Image
                      src={GreenDot}
                      alt="greenDot"
                      style={{
                        height: "8px",
                        width: "8px",
                        margin: "0px 5px",
                      }}
                      loading="lazy"
                    />
                    {item}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          position: "absolute",
          bottom: "0",
          right: "0px",
          height: "870px",
        }}
        sx={{ height: { xs: "50%", md: "100%" } }}
      >
        <Image
          src={bottomRightBackground}
          alt="bg"
          style={{ width: "100%" }}
          loading="lazy"
        />
      </Grid>
    </Grid>
  );
};

export default OurProducts;
